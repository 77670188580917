import integrations from "./integration";
export default [
  {
    path: "/:client_slug/settings/notes",
    name: "client.notes",
    component: () => import("@/pages/backend/settings/notes"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/email-generate",
    name: "client.email.gen",
    component: () => import("@/pages/backend/settings/emailgenerate"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/admin/service-ori",
    name: "admin.serviceori",
    component: () => import("@/pages/backend/servicesori/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/ori-report",
    name: "admin.enrollment.ori.report",
    component: () => import("@/pages/backend/report/admin/orireport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: true
    }
  },
  {
    path: "/admin/appointment-service-report",
    name: "admin.appointment.service.report",
    component: () =>
      import("@/pages/backend/report/admin/appointmentservicereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: true
    }
  },
  {
    path: "/admin/enrollment-service-report",
    name: "admin.enrollment.service.report",
    component: () =>
      import("@/pages/backend/report/admin/enrollmentservicereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: true
    }
  },
  //  tala ko service ho
  {
    path: "/admin/report/location-wise-report",
    name: "admin.report.location.wise.report",
    component: () => import("@/pages/backend/report/admin/locationwisereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin:true
    }
  },
  // tala ko company ho
  {
    path: "/admin/report/company-wise-report",
    name: "admin.report.company.wise.report",
    component: () => import("@/pages/backend/report/admin/companywisereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: true
    }
  },
  // tala ko mobile service ho
  {
    path: "/admin/report/location-job-report",
    name: "admin.report.location.job.wise.report",
    component: () => import("@/pages/backend/report/admin/locationjobreport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: true
    }
  },
  {
    path: "/:client_slug/ori-report",
    name: "client.enrollment.ori.report",
    component: () => import("@/pages/backend/report/enrollmentorireport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin: false
    }
  },
  {
    path: "/admin/service-codes",
    name: "admin.servicecodes",
    component: () => import("@/pages/backend/servicecodes/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/:client_slug/work-station",
    name: "client.apikey",
    component: () => import("@/pages/backend/apikey/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/dashboard",
    name: "client.dashboard",
    component: () => import("@/pages/backend/dashboard"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/analytical",
    name: "client.analytical",
    component: () => import("@/pages/backend/analytic/analytic"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/applicants",
    name: "client.applicants",
    component: () => import("@/pages/backend/applicant/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/invoice/:token/details",
    name: "client.invoice.details",
    component: () => import("@/pages/backend/appointment/invoice/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/enrollment-invoice/:enrollment_id/details",
    name: "client.enrollment.invoice.details",
    component: () =>
      import("@/pages/backend/appointment/invoice/enrollmentinvoice"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/appointment",
    name: "client.appointment.index",
    component: () => import("@/pages/backend/appointment/lists/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/appointment/:token/:app_id/:id/reschedule",
    name: "client.appointment.reschedule",
    component: () => import("@/pages/backend/appointment/reschedule/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/appointment-base-plan/:client",
    name: "client.appointment.baseplan",
    component: () => import("@/pages/backend/appointment/baseplan/newbaseplan"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false
    }
  },
  {
    path: "/:client_slug/renew-archive/:enroll_id/:tcn/:onsite",
    name: "client.changearchive.enrollment",
    component: () =>
      import("@/pages/backend/appointment/archival/changearchival"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "admin/:client_slug/renew-archive/:enroll_id/:tcn/:onsite",
    name: "admin.changearchive.enrollment",
    component: () =>
      import("@/pages/backend/appointment/archival/changearchival"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/job/lists",
    name: "client.job.list",
    component: () => import("@/pages/backend/jobs/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/job/:client_id?/:job_id/form",
    name: "client.job.form",
    component: () => import("@/pages/backend/jobs/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/job/:client_id/:job_id/details",
    name: "client.job.details",
    component: () => import("@/pages/backend/jobs/details"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/base-plan/:client/:location_id?/:location?",
    name: "client.baseplan.location",
    component: () => import("@/pages/backend/appointment/baseplan/newbaseplan"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/appointment-schedules",
    name: "client.appointment.schedule",
    component: () => import("@/pages/backend/appointment/schedule/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false
    }
  },
  {
    path: "/:client_slug/additional/:token/:app_id/:id/appointment/:onsite",
    name: "client.additional.appointmnet",
    component: () => import("@/pages/backend/appointment/additionalservice"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/archive/:token/:app_id/:client_id/appointment/:onsite",
    name: "client.archive.appointment",
    component: () => import("@/pages/backend/appointment/archive"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/new_service/:token/:app_id/:id/:onsite",
    name: "client.new.appointmnet.service",
    component: () => import("@/pages/backend/appointment/additionalnewservice"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/change-location/:token/:app_id/:id",
    name: "client.change.location",
    component: () => import("@/pages/backend/appointment/changeLocation"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/appointment-schedules/base-plan/store",
    name: "client.appointment.schedule.baseplan.store",
    component: () => import("@/pages/backend/appointment/schedule/baseplan"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false
    }
  },
  {
    path: "/:client_slug/appointment-schedules/base-plan/:id/update",
    name: "client.appointment.schedule.baseplan.update",
    component: () => import("@/pages/backend/appointment/schedule/baseplan"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: false
    }
  },
  {
    path: "/:client_slug/services",
    name: "client.service",
    component: () => import("@/pages/backend/master/service/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/service/create",
    name: "client.service.create",
    component: () => import("@/pages/backend/master/service/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/promocode/create",
    name: "client.promocode.create",
    component: () => import("@/pages/backend/master/promocode/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/service/:id/edit",
    name: "client.service.edit",
    component: () => import("@/pages/backend/master/service/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/abbreviations",
    name: "client.company",
    component: () => import("@/pages/backend/master/company/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/abbreviation/create",
    name: "client.company.create",
    component: () => import("@/pages/backend/master/company/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/abbreviation/:id/edit",
    name: "client.company.edit",
    component: () => import("@/pages/backend/master/company/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/promocodes",
    name: "client.promocode",
    component: () => import("@/pages/backend/master/promocode/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/promocode/:id/edit",
    name: "client.promocode.edit",
    component: () => import("@/pages/backend/master/promocode/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/locations",
    name: "client.location",
    component: () => import("@/pages/backend/master/location/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/location/create",
    name: "client.location.create",
    component: () => import("@/pages/backend/master/location/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/location/:id/edit",
    name: "client.location.edit",
    component: () => import("@/pages/backend/master/location/update"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/location/:id/users",
    name: "client.location.users",
    component: () => import("@/pages/backend/master/location/users"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/location/:id/rate",
    name: "client.location.rate",
    component: () => import("@/pages/backend/master/location/rateindex"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/users",
    name: "client.user",
    component: () => import("@/pages/backend/user/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/user/create",
    name: "client.user.create",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/user/:id/edit",
    name: "client.user.edit",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/settings/profile",
    name: "client.profile",
    component: () => import("@/pages/backend/settings/profile"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/settings/livescan-version",
    name: "client.setting.livescanversion",
    component: () => import("@/pages/backend/settings/livescanversion"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true,
      is_admin:true
    }
  },

  {
    path: "/:client_slug/settings/smpt-email-configure",
    name: "client.setting.email.configure",
    component: () => import("@/pages/backend/settings/smtpemailconfigure"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/admin/submission-configure",
    name: "admin.deporder-fdle-configure",
    component: () => import("@/pages/backend/settings/deporder_fdle_config"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/setup/training-link",
    name: "admin.setup.traininglink",
    component: () => import("@/pages/backend/settings/traininglink"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },

  {
    path: "/:client_slug/settings/payment",
    name: "client.setting.payment",
    component: () => import("@/pages/backend/settings/payment"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/settings/rates",
    name: "client.setting.defaultrate",
    component: () => import("@/pages/backend/settings/defaultrate"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/settings/default-location-rates",
    name: "client.setting.defaultlocationrate",
    component: () => import("@/pages/backend/settings/defaultlocationrate"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/settings/map_n_sms_config",
    name: "client.setting.map_n_sms_config",
    component: () => import("@/pages/backend/settings/map_n_sms_config"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/integrations",
    name: "client.integrations.index",
    component: () => import("@/pages/backend/integrations/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/integrations",
    name: "client.integrations.layout",
    component: () => import("@/pages/backend/integrations/layout"),
    children: [...integrations]
  },

  {
    path: "/:client_slug/logs/payment",
    name: "log.payment",
    component: () => import("@/pages/backend/logs/payment"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/admin/dashboard",
    name: "admin.dashboard",
    component: () => import("@/pages/backend/dashboard"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/service-provider",
    name: "admin.serviceprovider.index",
    component: () => import("@/pages/backend/serviceprovider/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/download-tcn",
    name: "admin.download.index",
    component: () => import("@/pages/backend/download/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },

  {
    path: "/admin/service-provider/create",
    name: "admin.serviceprovider.create",
    component: () => import("@/pages/backend/serviceprovider/create"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/service-provider/:id/edit",
    name: "admin.serviceprovider.edit",
    component: () => import("@/pages/backend/master/location/update"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/service-provider/:id/rate",
    name: "admin.serviceprovider.rate",
    component: () => import("@/pages/backend/serviceprovider/rateindex"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/service-provider/location/:id/users",
    name: "admin.serviceprovider.location.users",
    component: () => import("@/pages/backend/master/location/users"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/profile",
    name: "admin.profile",
    component: () => import("@/pages/backend/settings/profile"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },

  {
    path: "/admin/users",
    name: "admin.user",
    component: () => import("@/pages/backend/user/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/user/create",
    name: "admin.user.create",
    component: () => import("@/pages/backend/user/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/config/smpt-email-configure",
    name: "admin.email.configure",
    component: () => import("@/pages/backend/settings/smtpemailconfigure"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin: true
    }
  },
  {
    path: "/admin/config/map_config",
    name: "client.map_config",
    component: () => import("@/pages/backend/settings/map_n_sms_config"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/report/company-wise-report",
    name: "report.company.wise.report",
    component: () => import("@/pages/backend/report/companywisereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/company-invoice-detail",
    name: "client.company.invoice",
    component: () => import("@/pages/backend/invoice/company"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/client-invoice",
    name: "client.client.invoice",
    component: () => import("@/pages/backend/invoice/invoiceList"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/client-invoice/create/",
    name: "client.client.invoice.create",
    component: () => import("@/pages/backend/invoice/invoiceCreate"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/client-invoice/:id",
    name: "client.client.invoice.detail",
    component: () => import("@/pages/backend/invoice/invoiceDetail"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/admin/client-invoice/",
    name: "admin.client.invoice",
    component: () => import("@/pages/backend/invoice/invoiceList"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_admin:true,
      is_service_provider: true
    }
  },
  {
    path: "/admin/client-invoice/create/",
    name: "admin.client.invoice.create",
    component: () => import("@/pages/backend/invoice/invoiceCreate"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_admin:true,
      is_service_provider: true
    }
  },
  {
    path: "/admin/client-invoice/:id",
    name: "admin.client.invoice.detail",
    component: () => import("@/pages/backend/invoice/invoiceDetail"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_admin:true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/report/:company_id/invoice",
    name: "company.report.invoice",
    component: () => import("@/pages/backend/report/companyinvoice"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/report/:company_id/invoice-detail",
    name: "company.report.invoicedetail",
    component: () => import("@/pages/backend/report/companyappinvoice"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/report/location-wise-report",
    name: "report.location.wise.report",
    component: () => import("@/pages/backend/report/locationwisereport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/report/location-job-report",
    name: "report.location.job.wise.report",
    component: () => import("@/pages/backend/report/locationjobreport"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/report/location-wise-receivable-report",
    name: "report.location.wise.receivable.report",
    component: () => import("@/pages/backend/report/locationwisereceivable"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/groups",
    name: "client.groups",
    component: () => import("@/pages/backend/group/Index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/groups/:id",
    name: "client.group.service",
    component: () => import("@/pages/backend/group/service/Index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/workstation-devices/:id",
    name: "client.apikey.devices",
    component: () => import("@/pages/backend/apikey/devices/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/whitelist-email/",
    name: "client.whitelistemail",
    component: () => import("@/pages/backend/settings/whitelistemail/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/white-list/:id/edit",
    name: "client.whitelist.edit",
    component: () => import("@/pages/backend/settings/whitelistemail/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/white-list/create",
    name: "client.whitelist.create",
    component: () => import("@/pages/backend/settings/whitelistemail/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/featured",
    name: "client.featured",
    component: () => import("@/pages/backend/group/featured"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/store-forward",
    name: "client.storeforward",
    component: () => import("@/pages/backend/storeforward/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/training-links",
    name: "client.traininglinks",
    component: () => import("@/pages/backend/trainingdetail"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/store-forward/:tcn/:id/pdf",
    name: "client.storeforward.tcn.pdf",
    component: () => import("@/pages/backend/storeforward/pdf"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/timer-setup",
    name: "timer.setup",
    component: () => import("@/pages/backend/timer/Index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/roles",
    name: "client.roles",
    component: () => import("@/pages/backend/master/roles/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/archivals",
    name: "client.archival",
    component: () => import("@/pages/backend/master/archivals/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/archival/appointmentlist",
    name: "client.archivallist",
    component: () => import("@/pages/backend/appointment/archival/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/admin/archival/appointmentlist",
    name: "admin.archivallist",
    component: () => import("@/pages/backend/appointment/archival/admin/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin:true
    }
  },
  {
    path: "/:client_slug/roles/:id",
    name: "client.role.permissions",
    component: () => import("@/pages/backend/master/roles/rolepermission"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: true,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/workstation/logs",
    name: "log.apikeylogs",
    component: () => import("@/pages/backend/logs/apikey"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/archival/expiration",
    name: "client.archival.expiration",
    component: () =>
      import("@/pages/backend/appointment/archival/nearexpiration"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/admin/archival/expiration",
    name: "admin.archival.expiration",
    component: () =>
      import("@/pages/backend/appointment/archival/admin/nearexpiration"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: false,
      is_admin:true
    }
  },
  {
    path: "/:client_slug/master-companies",
    name: "client.master.company",
    component: () => import("@/pages/backend/master/mastercompany/index"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
  {
    path: "/:client_slug/master-company/create",
    name: "client.master.company.create",
    component: () => import("@/pages/backend/master/mastercompany/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/master-company/:id/edit",
    name: "client.master.company.edit",
    component: () => import("@/pages/backend/master/mastercompany/form"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },

  {
    path: "/:client_slug/quickbooks-setup",
    name: "quickbook.setup",
    component: () => import("@/pages/backend/settings/quickbook/configuration.vue"),
    meta: {
      requiresAuth: true,
      permission: "",
      is_client: false,
      is_service_provider: true
    }
  },
];
