import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import {
  FETCH_NOTES,
  UPDATE_NOTES,
  FETCH_F_NOTE,
  FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE,
  STORE_SMTP_OUTGOING_EMAIL_CONFIGURE,
  CHECK_SMTP_CONNECTION,
  FETCH_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING,
  FETCH_SMS_CONFIG,
  SUBMIT_SMS_CONFIG,
  FETCH_MAP_CONFIG,
  SUBMIT_MAP_CONFIG,
  FETCH_EMAIL_TEMPLETE,
  UPDATE_EMAIL_TEMPLETE,
  FETCH_HEART_PAYMENT_SETTING,
  UPDATE_HEART_PAYMENT_SETTING,
  STORE_TIMER_SETUP,
  GET_TIMER_SETUP,
  UPDATE_TIMER_SETUP,
  FETCH_DEPORDER_CONFIG,
  SUBMIT_DEPORDER_CONFIG,
  FETCH_FDLE_CONFIG,
  FETCH_ACHA_CONFIG,
  FETCH_TRAINING_LINK,
  SUBMIT_TRAINING_LINK,
  SUBMIT_ACHA_CONFIG,
  SUBMIT_FDLE_CONFIG,
  DELETE_WHITELIST_EMAIL,
  GET_WHITELIST_EMAIL_LIST,
  CREATE_WHITELIST_EMAIL,
  FETCH_WHITELISTEMAIL_DETAILS,
  FETCH_APOSTILLE_CONFIG,
  SUBMIT_APOSTILLE_CONFIG
} from "../actions.type";
import { SET_SETTING_NOTE, SET_WHITELIST_DETAILS } from "../mutations.type";
// import {
//   SET_F_CLIENTS,
//   SET_ERROR,
//   SET_F_CLIENT_NAME,
//   SET_F_CLIENT_RATE,
//   SET_F_ALERT_MSG,
//   SET_RATE_TOTAL_AMOUNT,
//   SET_ADD_RATE_QTY,
//   SET_CHECK_F_ABBREVIATION_CODE,
//   UPDATE_ABBREVIATION_CODE
// } from "../mutations.type";

const state = {
  settingNote: null,
  whilelistdetail: ""
};
const getters = {
  getSettingNote(state) {
    return state.settingNote;
  },
  getWhiteListDetail(state) {
    return state.whilelistdetail;
  }
};
const actions = {
  [FETCH_SMS_CONFIG]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/sms-config")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_SMS_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/sms-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_DEPORDER_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/deporder-config", '', {params:{is_trp:data}})
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_DEPORDER_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/deporder-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_FDLE_CONFIG]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/fdle-config")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_FDLE_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/fdle-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_APOSTILLE_CONFIG]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/submit/get/apostille-config")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_APOSTILLE_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/add/apostille-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_ACHA_CONFIG]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/acha-config")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_ACHA_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/acha-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_TRAINING_LINK]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/training-link")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_TRAINING_LINK](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/training-link/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_EMAIL_TEMPLETE]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/email-template")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_EMAIL_TEMPLETE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/email-template/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [FETCH_MAP_CONFIG]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("setting/get/map-config")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [SUBMIT_MAP_CONFIG](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/submit/map-config/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  [UPDATE_NOTES](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("setting/notes/update/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_SMTP_OUTGOING_EMAIL_CONFIGURE]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/configure/smtp-outgoing-email")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [UPDATE_PAYMENT_SETTING](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("add/payment-setting/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_PAYMENT_SETTING]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/payment-setting")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [UPDATE_HEART_PAYMENT_SETTING](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("add/heartland/payment-setting/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_HEART_PAYMENT_SETTING]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/heartland/payment-setting")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ err }) => {
            reject(err);
          });
      });
    }
  },
  [STORE_SMTP_OUTGOING_EMAIL_CONFIGURE](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("add/configure/smtp-outgoing-email/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [CHECK_SMTP_CONNECTION](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("test/configure/smtp-outgoing-email/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_NOTES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("setting/notes")
        .then(({ data }) => {
          context.commit(SET_SETTING_NOTE, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },

  [FETCH_F_NOTE](context, clientSlug) {
    return new Promise((resolve, reject) => {
      ApiService.get("f/note", clientSlug)
        .then(({ data }) => {
          context.commit(SET_SETTING_NOTE, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [STORE_TIMER_SETUP](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("appointment-time/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [UPDATE_TIMER_SETUP](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.put("appointment-time/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [DELETE_WHITELIST_EMAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("delete/white-list-email/" + data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_WHITELIST_EMAIL_LIST]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/white-list-email/list")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [FETCH_WHITELISTEMAIL_DETAILS](context, id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("get/white-list-email/" + id)
          .then(({ data }) => {
            context.commit(SET_WHITELIST_DETAILS, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },

  [CREATE_WHITELIST_EMAIL](context, data) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.post("create/white-list-email/", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  },
  [GET_TIMER_SETUP]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get("appointment-time")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data);
          });
      });
    }
  }
};
const mutations = {
  [SET_SETTING_NOTE](state, data) {
    state.settingNote = data;
  },
  [SET_WHITELIST_DETAILS](state, data) {
    state.whilelistdetail = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
